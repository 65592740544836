import React from 'react';
import { Link } from 'gatsby';
import Container from '../components/shared/Container';
import SEO from '../components/shared/SEO';
import Faqs from '../components/shared/FAQ';
import Maker from '../components/shared/Maker';
import GiveFeedback from '../components/shared/GiveFeedback';
import introVocabCover from '../images/ebooks/kumadex-introductory-vocab.png';
import jlptN5Cover from '../images/ebooks/kumadex-jlpt-n5-50-words.png';
import jlptN4Cover from '../images/ebooks/kumadex-jlpt-n4-25-words.png';
import jlptN3Cover from '../images/ebooks/kumadex-jlpt-n3-25-words.png';
import jlptN2Cover from '../images/ebooks/kumadex-jlpt-n2-25-words.png';

const Card = ({
  imgSrc,
  title,
  exclusive,
  productDetailsUrl,
  description,
  ctaUrl,
  ctaText,
  ctaType,
}) => (
  <li>
    <div className="space-y-4">
      <Link to={productDetailsUrl}>
        <div className="aspect-w-3 aspect-h-2">
          <img
            className="object-cover shadow-lg rounded-lg"
            src={imgSrc}
            alt={description}
          />
        </div>
      </Link>
      <div className="text-lg leading-6 font-medium space-y-1">
        <div className="flex items-center space-x-3">
          <h3>Kumadex</h3>
          {exclusive && <span className="flex-shrink-0 inline-block px-2 py-0.5 text-yellow-800 text-xs font-medium bg-yellow-100 rounded-full">
            Exclusive
          </span>}
        </div>
        <p className="text-green-600">
          <Link to={productDetailsUrl}>
            {title}
          </Link>
        </p>
      </div>
      <div className="text-lg">
        <p className="text-gray-500">
          {description}
        </p>
      </div>

      <a
        href={ctaUrl}
        target="_blank"
        rel="noreferrer noopener"
        className={`w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium ${ctaType === 'secondary' ? 'text-green-600 border-2 border-green-600 hover:bg-gray-50' : 'text-white bg-green-600 hover:bg-green-700'}`}
      >
        {ctaText}
      </a>
    </div>
  </li>
);

const Ebooks = () => (
  <div>
    <Maker />
    <GiveFeedback />
    <SEO
      title="Kuma Learn - eBooks - Learn Japanese visually"
      description="Learn JLPT-N5, JLPT-N4, JLPT-N3, JLPT-N2, JLPT-N1, Genki and anime Japanese voabulary with pictures. Download a free mini-eBook today."
    />
    <div className="bg-white">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
          <div className="space-y-5 sm:space-y-4">
            <h1 className="text-3xl font-extrabold tracking-tight sm:text-4xl">eBooks</h1>
            <p className="text-xl text-gray-500">
              Diversify your approach to learning Japanese with visual resources with Kuma Learn!
              Each eBook comes with notes on culture, how-tos and more.
              By buying exclusive resources, you&apos;ll also be helping Kuma Learn keep the lights
              on (server costs, etc).
            </p>
          </div>

          <div className="lg:col-span-2">
            <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8">
              <Card
                imgSrc={introVocabCover}
                exclusive
                productDetailsUrl="/ebooks/kumadex-introductory-vocab"
                title="Learn Introductory Vocab with Pictures"
                description="Packed with 400+ words with pictures, cultural notes and more, this eBook is your companion for visual learning."
                ctaUrl="https://gum.co/kuma-learn-intro-japanese-vocab-with-pictures"
                ctaText="Buy exclusive eBook"
              />
              <Card
                imgSrc={jlptN5Cover}
                productDetailsUrl="/ebooks/kumadex-jlpt-n5-50-words"
                title="Learn 50 JLPT-N5 Vocab with Pictures"
                description="I carefully selected frequently occurring 50 JLPT-N5 words in this mini-eBook to start your visual learning journey."
                ctaUrl="https://gumroad.com/l/zGBJT"
                ctaText="Get free eBook"
                ctaType="secondary"
              />
              <Card
                imgSrc={jlptN4Cover}
                title="Learn 25 JLPT-N4 Vocab with Pictures"
                productDetailsUrl="/ebooks/kumadex-jlpt-n4-25-words"
                description="I carefully selected frequently occurring 25 JLPT-N4 words in this mini-eBook to start your visual learning journey."
                ctaUrl="https://gumroad.com/l/ofxTC"
                ctaText="Get free eBook"
                ctaType="secondary"
              />
              <Card
                imgSrc={jlptN3Cover}
                title="Learn 25 JLPT-N3 Vocab with Pictures"
                productDetailsUrl="/ebooks/kumadex-jlpt-n3-25-words"
                description="I carefully selected frequently occurring 25 JLPT-N3 words in this mini-eBook to start your visual learning journey."
                ctaUrl="https://gumroad.com/l/XvDwH"
                ctaText="Get free eBook"
                ctaType="secondary"
              />
              <Card
                imgSrc={jlptN2Cover}
                title="Learn 25 JLPT-N2 Vocab with Pictures"
                productDetailsUrl="/ebooks/kumadex-jlpt-n2-25-words"
                description="I carefully selected frequently occurring 25 JLPT-N2 words in this mini-eBook to start your visual learning journey."
                ctaUrl="https://gumroad.com/l/hPqUk"
                ctaText="Get free eBook"
                ctaType="secondary"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Faqs />
    </div>
  </div>
);

const EbooksPage = () => (
  <Container>
    <Ebooks />
  </Container>
);

export default EbooksPage;
