import React, { useState } from 'react';
import { Link } from 'gatsby';
import logo from '../../images/logo_with_text.svg';

const Header = () => {
  const [open, setOpen] = useState(false);
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-8">
        <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start md:w-0 md:flex-1">
            <Link to="/">
              <span className="sr-only">Kuma Learn</span>
              <img className="h-8 w-auto sm:h-10" src={logo} alt="Kuma Learn" width="180" />
            </Link>
          </div>
          <div className="hidden md:flex">
            <div className="md:ml-10 md:pr-4 md:space-x-8">
              <Link to="/about" className="font-medium text-gray-500 hover:text-gray-900">About</Link>
            </div>
            <div className="md:ml-4 md:pr-4 md:space-x-8">
              <a
                href="https://app.kumalearn.com/dictionary?utm_medium=web&utm_source=www&utm_campaign=header"
                className="flex items-center font-medium text-gray-500 hover:text-gray-900"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                Dictionary
              </a>
            </div>
            <div className="md:ml-4 md:pr-4 md:space-x-8">
              <a
                href="https://app.kumalearn.com/srs?utm_medium=web&utm_source=www&utm_campaign=header"
                className="flex items-center font-medium text-gray-500 hover:text-gray-900"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                SRS
              </a>
            </div>
            <div className="md:ml-4 md:pr-4 md:space-x-8">
              <Link to="/ebooks" className="font-medium text-gray-500 hover:text-gray-900">eBooks</Link>
            </div>
            <div className="md:ml-4 md:pr-4 md:space-x-8">
              <a
                href="https://blog.kumalearn.com?utm_medium=web&utm_source=www&utm_campaign=header"
                className="font-medium text-gray-500 hover:text-gray-900"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                Blog
              </a>
            </div>
            <div className="md:ml-4 md:pr-4 md:space-x-8">
              <a
                href="https://kumalearn.gumroad.com/l/lrrro?utm_medium=web&utm_source=www&utm_campaign=header"
                className="font-medium text-gray-500 hover:text-gray-900"
                rel="noopener noreferrer nofollow"
                target="_blank"
              >
                Buy me a coffee
              </a>
            </div>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              onClick={() => setOpen(true)}
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
              <span className="sr-only">Open menu</span>
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {open && (
        <div className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img className="h-8 w-auto" src={logo} alt="Kuma Learn" width="180" />
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    onClick={() => setOpen(false)}
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                    <span className="sr-only">Close menu</span>
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="mt-7">
                <nav className="grid gap-y-8">
                  <Link to="/about" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                    <span className="text-base font-medium text-gray-900">
                      About
                    </span>
                  </Link>
                  <a
                    href="https://app.kumalearn.com/dictionary?utm_medium=web&utm_source=www&utm_campaign=header"
                    className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 text-base font-medium text-gray-900"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    Dictionary
                  </a>
                  <a
                    href="https://app.kumalearn.com/srs?utm_medium=web&utm_source=www&utm_campaign=header"
                    className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 text-base font-medium text-gray-900"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    SRS
                  </a>
                  <Link to="/ebooks" className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
                    <span className="text-base font-medium text-gray-900">
                      eBooks
                    </span>
                  </Link>
                  <a
                    href="https://blog.kumalearn.com?utm_medium=web&utm_source=www&utm_campaign=header"
                    className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 text-base font-medium text-gray-900"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    Blog
                  </a>
                  <a
                    href="https://kumalearn.gumroad.com/l/lrrro?utm_medium=web&utm_source=www&utm_campaign=header"
                    className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 text-base font-medium text-gray-900"
                    rel="noopener noreferrer nofollow"
                    target="_blank"
                  >
                    Buy me a coffee
                  </a>
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div>
                <Link
                  to="/ebooks"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-600 hover:bg-green-700"
                >
                  See eBooks
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
